.voucherCard {
  background: #F1FCFF;
  border: 0.5px solid #0DA6C8;
  width: 100%;
}

.discountText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #212529;
}

.fontText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #212529;
}

.codeCard {
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  border: 1px dashed #02C8F4;
}
.flex3 { 
  display: flex; 
  justify-content: space-between; 
  margin-top: 5%;
} 

.sampleButton{
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
background: #F1FCFF;
border-radius: 4px;
border-style: none;
color:#0DA6C8;
pointer-events: none;
}

/* switchOrignalSample */
.fontRoboto127 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  border-style: none;
  position: absolute;
  border-radius: 100px;
  box-sizing: border-box;
  width: 88px;
  height: 32px;
  transform-style: preserve-3d;
  transition: all 0.1s ease-in-out;
}



.fontRoboto128 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  border-style: none;
  width: 82px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 100px;
  transform-style: preserve-3d;
  transition: all 0.1s ease-in-out;
}

.button-cover {
  box-sizing: border-box;
  width: 178px;
  height: 38px;
  background: #F9F9F9;
  border: 0.5px solid #C4C4C4;
  border-radius: 100px;
}

.RepeatModalFooter .editButton{
  background: #00C50D;
  border: 0.5px solid #378D3D;
  box-sizing: border-box;
  width: 45%;
}
.RepeatModalFooter .cancelButton{
  box-sizing: border-box;
  background: #E31B1B;
  border: 0.5px solid #8A151D;
  width: 45%;
}

.react-datepicker__header ,.react-datepicker__month-dropdown {
  background-color: #980033 !important;
  color:#ffff
}
.react-datepicker__current-month,.react-datepicker__day-name {
  color: #ffff !important;
}

/* Button 10 */