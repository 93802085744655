
  /* skuToogle */
  .fontRobotoNo {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-style: none;
    position: absolute;
    border-radius: 100px;
    box-sizing: border-box;
    width: 61px;
    height: 32px;
    transform-style: preserve-3d;
    transition: all 0.1s ease-in-out;
  }
  
  .skuToogle button:focus{
    outline: none;
    box-shadow: none;
  }
  
  .fontRobotoYes {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    border-style: none;
    width: 59px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 100px;
    transform-style: preserve-3d;
    transition: all 0.1s ease-in-out;
  }
  
  .button-coverSku {
    box-sizing: border-box;
    width: 126px;
    height: 38px;
    background: #F9F9F9;
    border: 0.5px solid #C4C4C4;
    border-radius: 100px;
  }
  .fileCover {
    border: 0.5px solid #C4C4C4;
    text-align: end;
    padding: 7px;
    margin: 3px;
    background: #F9F9F9;
    border-radius: 4px;
    height: 40px;
    justify-content: center;
    overflow: hidden;
  }
  .AttachedSkuImage {
    position: relative;
    right: 44px;
    top: 29.33%;
    bottom: 68.64%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: #5E5E5E !important;
    cursor: pointer;
  }
  .imageNameText {
    right: 52.68%;
    bottom: 184.64%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #5E5E5E;
    position: relative;
  }

  /* skuToogle */