/* Loader css*/
.loaderDiv {
  padding: 100px;
}

.ViewPagesLoder {
  position: relative;
  top: 40%;
}

.ViewPagesLoder1 {
  padding: 200px;
}

/*common css*/
body {
  color: #212529;
  overflow: hidden;
}

a {
  color: #212529;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #980033;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mt-8 {
  margin-top: 8px;
}

.pd-0 {
  padding: 0px;
}

.pd-10 {
  padding: 10px;
}

.pdr-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-0 {
  margin-bottom: 0px;
}

.h-100 {
  height: 100%;
}

.pd-15 {
  padding: 15px;
}

.pdt-10 {
  padding-top: 10px;
}

.pdt-15 {
  padding-top: 15px;
}

.pd-25 {
  padding: 25px;
}

.pdtb-40 {
  padding: 40px 0px;
}

.pdrl-15 {
  padding: 0px 15px;
}

.pdrl-25 {
  padding: 0px 25px;
}

.bg-white {
  background: #fff;
}

.borderedDiv {
  border: 0.5px solid #c4c4c45e;
  border-radius: 4px;
}

.borderedPlainBottomDiv {
  border-bottom: 0.5px solid #c4c4c45e;
}

.noBorderedDiv {
  border: 0px !important;
}

.width-8 {
  width: 8% !important;
}

.width-92 {
  width: 92% !important;
}

h2 {
  font-size: 32px;
  font-weight: 900;
}

.pos-rel {
  position: relative;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

ul {
  padding-left: 0px;
}

ul li {
  list-style-type: none;
  padding: 0px 5px;
}

.borderRight {
  border-right: 0.5px solid #c4c4c4;
}

.form-control {
  background-color: #f9f9f9;
  border-color: #c4c4c4;
}

.btn {
  font-size: 15px;
  border: 0.5px solid #5e5e5e;
  padding: 7px 15px;
  border-radius: 4px;
}

.lightBtn {
  color: #5e5e5e;
  background: #fff;
  min-width: 80px;
}

.darkBtn {
  color: #fff;
  background: #5e5e5e;
  min-width: 150px;
}

.dashNewRequestsInnerDiv {
  padding: 0px !important;
  border: 0px !important;
}

.dashNewRequestsInnerDiv .progressDiv {
  margin-left: 0px !important;
  width: 50% !important;
}

.contentheading p {
  font-size: 24px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 0px;
}

.svgStyleDiv {
  padding: 5px 13px;
  background: #f9f9f9;
  border-radius: 4px;
  margin-right: 15px;
  cursor: pointer;
}

.svgStyleDiv svg {
  width: 12px;
  height: 12px;
}

#layout-wrapper {
  background: #f9f9f9;
}

tbody + thead {
  display: none;
}

.dataTableStyles thead {
  background-color: #f2f2f2;
  color: #212529;
  line-height: 21px;
  font-size: 14px;
}

.table {
  border: 0px;
}

.table th {
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif;
  border: 0px;
}

.table td {
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #5e5e5e;
  vertical-align: middle;
  padding: 20px 5px !important;
  border-bottom: 0.5px solid #c4c4c45e;
}

.CouponCode .table td {
  padding: 10px 14px !important;
}
.CouponCode {
  overflow-y: scroll;
  max-height: 370px !important;
}

.table td,
.table th {
  text-align: left;
}

.addProductList .addProductstyle .table th {
  text-align: center;
}

.inventorySearchDiv input {
  width: auto !important;
  margin-left: 10px;
}

.tableProgressBar {
  position: absolute;
  top: -2px;
  width: 100%;
  text-align: center;
  font-size: 12px;
}

/*dashboard css*/
.bg-new .progress-bar {
  background: linear-gradient(270deg, #02c8f4 70.3%, #00c50d 100%) !important;
}

.dashInnerMainDiv {
  padding: 15px;
  border: 0.5px solid #c4c4c45e;
  /* #c4c4c4 */
  background: #fff;
  border-radius: 4px;
}

.dashInnerDivs {
  padding: 15px;
  border: 0.5px solid #c4c4c45e;
  border-radius: 4px;
  color: #5e5e5e;
}
.datePicker {
    width: 100%; 
    height: 40;
    border: 1px solid #c4c4c4;
    border-radius:5px
}
.inputDate{
  color: black;
  height: 0;
  width: 0;
  position: absolute;
  opacity: 0;
}
.datePickerButton:active, .datePickerButton:hover {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #000 !important;
}
.datePickerButton{
  width:100%;
  background:#f9f9f9;
  color:#000;
  border:none;
  text-align:start;
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.dashInnerDivs img {
  width: 60px;
  margin-right: 15px;
}

.dashInnerHeadDiv {
  border-bottom: 0.5px solid #c4c4c45e;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.dashInnerHeadDiv h4 {
  font-size: 20px;
  margin-bottom: 0px;
}

.dashInnerDivs button {
  background-color: transparent;
  color: #5e5e5e;
  font-size: 15px;
  border: 0.5px solid #5e5e5e;
}

.dashInnerDivs .progressDiv {
  width: 50vh;
  margin-left: 25px;
}

.dashInnerDivs p {
  font-size: 18px;
}

.p-head {
  font-weight: 500;
  font-size: 18px;
}

.dashInnerDivs p:last-child {
  font-size: 24px;
  font-weight: 700;
  color: #212529;
}

.dashInnerDivs .progressDiv .progress {
  border: 0.5px solid #c4c4c4;
  background-color: #fff;
  border-radius: 4px;
}

.dashInnerDivs .progressDiv .progress-bar {
  background: linear-gradient(270deg, #02c8f4 3.3%, #00c50d 100%);
}

/*header css*/
.sidebarHeaderSec {
  padding: 0px;
  padding-top: 15px;
  background: #fff;
  border: 0.5px solid #c4c4c45e;
  position: relative;
  height: 100vh;
  overflow-y: auto;
}

.sidebarHeaderSec ul a {
  text-decoration: none;
}

.headerSidebar .svgStyleDiv {
  position: absolute;
  right: -40px;
  /* z-index: 9999; */
  top: 35px;
  padding: 12px 15px;
}

.headerSidebar .miniHeaderDiv ul li {
  justify-content: center;
}

.headerSidebar .miniHeaderDiv ul li svg,
.headerSidebar .miniHeaderDiv ul li img.custgrey,
.headerSidebar .miniHeaderDiv ul .active li img,
.headerSidebar .miniHeaderDiv ul li:hover img,
.headerSidebar .miniHeaderDiv ul li:focus img {
  margin-right: 0px !important;
}

.sidebarHeaderSec button {
  background: #980033;
  font-size: 16px;
  width: 100%;
  padding: 10px 8px;
  outline: 0 !important;
}

.sidebarHeaderSec button:hover,
.sidebarHeaderSec button:focus {
  background: #980033;
  outline: 0 !important;
}

.addCustBtn {
  padding: 0px 15px;
}

.sidebarHeaderSec ul {
  padding: 0px 0px 0px 15px;
}

.menulink {
  text-decoration-line: none;
}

.sidebarHeaderSec ul li.active,
.sidebarHeaderSec ul li:hover,
.sidebarHeaderSec ul li:focus .sidebarHeaderSec ul .active li,
.sidebarHeaderSec ul .active li img,
.sidebarHeaderSec ul .active li {
  color: #980033;
  background: #f2f2f2;
  display: flex;
}

.sidebarHeaderSec ul li.active svg,
.sidebarHeaderSec ul li:hover svg,
.sidebarHeaderSec ul li:focus svg,
.sidebarHeaderSec ul li img,
.sidebarHeaderSec ul .active li img.custgrey,
.sidebarHeaderSec ul li:hover img.custgrey,
.sidebarHeaderSec ul li:focus img.custgrey,
.sidebarHeaderSec ul .active li svg {
  display: none;
}

.sidebarHeaderSec ul li img.adminImageIcon,
.sidebarHeaderSec ul li.active img,
.sidebarHeaderSec ul li:hover img,
.sidebarHeaderSec ul li:focus img,
.sidebarHeaderSec ul li img.custgrey,
.sidebarHeaderSec ul .active li img {
  display: block;
}

.sidebarHeaderSec ul li svg,
.sidebarHeaderSec button svg,
.sidebarHeaderSec ul li img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.sidebarHeaderSec .sidebarProfilenameDiv {
  text-align: center;
}

.sidebarProfilenameDiv h5 {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #5e5e5e;
}

.rightSideNavbar {
  padding: 25px 25px;
  background: #fff;
  border: 0.5px solid #c4c4c45e;
  border-radius: 4px;
}

.rightSideNavbar .rightSideNavbarLeftDiv h4 {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 700;
  color: #212529;
}

.rightSideNavbar .rightSideNavbarLeftDiv .svgStyleDiv,
.rightSideNavbar .rightSideNavbarRightDiv .svgStyleDiv {
  padding: 5px 13px;
  background: #f9f9f9;
  border-radius: 4px;
  margin-right: 15px;
}

.rightSideNavbar .rightSideNavbarLeftDiv .svgStyleDiv svg,
.rightSideNavbar .rightSideNavbarRightDiv .svgStyleDiv svg {
  width: 12px;
  height: 12px;
}

.rightSideNavbar .rightSideNavbarRightDiv .menuProfileStyleDiv {
  margin: 0px 15px;
  margin-right: 25px;
}

.rightSideNavbar .rightSideNavbarRightDiv .menuProfileStyleDiv img {
  margin-right: 15px;
}

.rightSideNavbar .rightSideNavbarRightDiv p {
  margin-bottom: 0px;
  font-size: 20px;
  color: #5e5e5e;
  font-weight: 700;
  line-height: 22px;
}

.rightSideNavbar .rightSideNavbarRightDiv p span {
  font-size: 15px;
  font-weight: 400;
}

.menuHeaderPathDiv ul,
.contentInnerDiv .catogoriesListingDiv ul {
  padding-left: 0px;
}

.menuHeaderPathDiv ul li {
  list-style-type: none;
  padding: 0px 10px;
  color: #c4c4c4;
  font-size: 18px;
}

.menuHeaderPathDiv ul li svg {
  width: 15px;
  height: 15px;
}

.menuHeaderPathDiv ul li:last-child {
  color: #980033;
}

.contentInnerDiv {
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 3px -2px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 3px -2px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 3px -2px rgb(0 0 0 / 75%);
}

.contentInnerDiv p {
  font-size: 24px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 0px;
}

.contentInnerDiv .catogoriesListingDiv ul {
  margin-bottom: 0px;
}

.contentInnerDiv .catogoriesListingDiv ul li {
  padding: 10px 15px;
  list-style-type: none;
  background: #f9f9f9;
  margin-right: 10px;
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
}

.contentInnerDiv .catogoriesListingDiv button {
  background: none;
  border: 0.5px solid #5e5e5e;
  color: #5e5e5e;
  font-size: 14px;
  border-radius: 4px;
  padding: 5px 25px;
}
.priceinputSize{
  width: 60px !important;
}
.headerSec .collapse {
  height: 100%;
  padding-bottom: 10px;
}

.headerSec form .mb-3 {
  margin-bottom: 0px !important;
}

.Headerbutton {
  background: transparent;
  color: #333;
  border-color: #eee;
  padding: 6px 25px;
}

.nav {
  border-bottom: 2px solid #b3b3b3;
}

.nav-link {
  color: #bfbfbf;
  font-weight: 500;
}

.nav-link.active,
.nav-link:hover,
.nav-link:focus {
  color: #980033;
  cursor: pointer;
}

/*home page*/
.mainContent {
  padding: 40px 25px;
}

.categoryImageSection {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}

.categoryImageSection img {
  width: 100%;
}

.categoryImageSection .categoryNameDiv {
  position: absolute;
  bottom: 0px;
  left: 25px;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.CarouseDivTwoContainer,
.CarouseDivTwo,
.CarouseDivTwo .card-body {
  background: #f9f9f9;
}

.CarouseDivTwo h4 {
  font-size: 28px;
  margin-bottom: 0px;
  font-weight: 400;
}

.carouselTowHeader {
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 0px;
}

.carouselTowHeader span {
  font-weight: 400;
}

.CarouseDivTwo {
  border: 0px;
  margin: 15px 0px;
}

.CarouseDivTwo .card-body {
  margin: 0px 15px;
  padding: 0px;
  border-right: 0.5px solid #5e5e5e;
}

.CarouseDivTwo CardText,
.CarouseDivTwo p {
  font-size: 26px;
  font-weight: 400 !important;
}

.CarouseDivTwo p:last-child {
  margin-top: 10px;
}

.CarouseDivTwo p:last-child.belowValue {
  color: #ff1a00;
}

.CarouseDivTwo p:last-child.belowValue span svg {
  fill: #ff1a00;
}

.CarouseDivTwo p:last-child.aboveValue {
  color: #00c50d;
}

.CarouseDivTwo p:last-child.aboveValue span svg {
  fill: #00c50d;
  transform: rotate(180deg);
}

.CarouseDivTwo p span {
  padding-left: 30px;
}

.footDivStyle {
  /* position: fixed; */
  bottom: 0px;
  padding: 0px 25px;
  width: 100%;
  color: #212529;
  font-size: 15px;
}

.loginDivCss {
  background: url("./images/signup_bg.jpg");
  background-position: center;
  background-size: cover;
  color: #5e5e5e;
  min-height: 109vh;
  display: flex;
  align-items: center;
}

.loginDivCss .form-control {
  text-align: center;
}

.checkDivCss {
  font-size: 12px;
  color: #212529;
  margin-bottom: 25px;
}

.checkDivCss label {
  margin-bottom: 0px;
  margin-left: 10px;
}
.input-group{
  position: initial !important;
}
.paginations{
  display: flex;
  justify-content: space-between;
}
.productBntFit {
  display: flex;
  justify-content: flex-start;
}
.signupDivCss .loginLogLeft {
  background: url("./images/signupbg.jpg");
  color: #212529;
}

.signupDivCss .loginLogLeft.loginDescDiv h1 {
  color: #980033;
}

.loginDivCss .loginLogRight {
  background: #fff;
  padding: 40px 80px;
  text-align: center;
  margin: auto;
}

.loginDivCss .loginLogLeft .loginDescDiv {
  width: 40%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
}

.loginDescDiv h1 {
  font-size: 36px;
  font-weight: 900;
}

.loginDescDiv p {
  font-size: 20px;
  line-height: 25px;
  font-style: italic;
}

.loginDescDiv p:last-child {
  font-size: 15px;
}

.loginDescDiv hr {
  border-bottom: 10px solid #fff;
}

.homeLoginDivCss .homeLogRight {
  background: #f9f9f9;
  padding: 40px;
  text-align: center;
}

.loginLogRight h2 {
  color: #980033;
  font-size: 26px;
}

.homeLoginDivCss input {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.homeLoginDivCss button {
  padding: 5px 40px;
  border-radius: 0px;
}

/*products page*/
.productsContent .form-select,
.productsContent .form-select:focus,
.productsContent .form-select:hover {
  border: 0px !important;
  outline: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.productsContent .leftCol p,
.productsContent .leftCol .form-select {
  font-size: 18px;
}

.productsContent .leftCol p,
.productsContent .leftCol .inputDiv {
  padding: 0px 12px;
}

.productsContent .leftCol .inputDiv label {
  padding-right: 20px;
}

.productsContent .leftCol .inputDiv label,
.productsContent .leftCol .inputDiv input::placeholder {
  color: #c4c4c4;
}

.productsContent .leftCol {
  padding-left: 0px;
}

.leftColInnerDiv button {
  width: 50%;
  margin: 10px 15px;
}

.rightColInnerDiv img {
  width: 100%;
}

.rightColInnerDiv h3,
.rightColInnerDiv h5 {
  font-size: 18px;
  font-weight: 700;
}

.rightColInnerDiv p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.leftColInnerDiv {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(17, 17, 17, 0.18);
}

.rightColInnerDiv {
  margin: 25px 0px;
  margin-top: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(17, 17, 17, 0.18);
}

.buttonDivStyle {
  /* background: #212529; */
  background: #5e5e5e;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  padding: 10px;
}

.productDetailsMainContent .buttonDivStyle {
  position: relative;
  padding-left: 40px;
  text-align: left;
  display: flex;
  align-items: center;
}

.productDetailsMainContent .buttonDivStyle svg {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 8px;
}

.rightColInnerDiv .buttonDivStyle {
  padding: 10px;
  width: 100%;
}

.rightColInnerDiv .productImageDiv {
  position: relative;
}

.rightColInnerDiv .productImageDiv p {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.filterProductsDiv .form-select,
.filterProductsDiv .form-select:hover,
.filterProductsDiv .form-select:focus {
  border: 0.5px solid #707070 !important;
  width: 65%;
}

.filterProductsDiv p {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0px;
}

.filterProductsDiv label {
  font-size: 23px;
  font-weight: 400;
  margin-right: 20px;
  margin-bottom: 0px;
}

.filterProductsDiv Form {
  width: 25%;
}

.filterProductsDiv {
  margin-bottom: 25px;
}

.sliderDiv {
  margin-top: 10px;
}

.sliderDiv .slick-prev:before,
.sliderDiv .slick-next:before {
  color: #777;
}

.sliderDiv img {
  width: 150px;
  height: 100%;
}

.sliderDiv .slideImgDiv {
  padding: 0px;
}

.sliderDiv .card {
  flex-direction: initial;
}

.sliderDiv .card p {
  font-size: 18px;
  color: #5e5e5e;
  font-weight: 400;
  margin-bottom: 3px;
}

.sliderDiv .card .card-body .card-text {
  font-size: 20px;
  font-weight: 700;
  color: #212529;
  line-height: 25px;
}

.sliderDiv .slick-slide {
  padding: 10px;
}

.sliderDiv .slick-dots li button:before {
  font-size: 9px;
}

.sliderDiv .slick-dots li.slick-active button:before {
  font-size: 12px;
}

.sliderDiv .slick-dots li {
  margin: 0px 0px;
}

/*footer page*/
.footerContent {
  background: #212529;
  color: #fff;
}

.footerHrDiv {
  border: 8px solid #980033;
  width: 60%;
}

.footerContent h2 {
  padding: 0px 20px;
}

.footerContent ul {
  padding-left: 0px;
}

.footerContent ul li {
  list-style-type: none;
  padding: 10px 25px;
}

.footerContent ul li:last-child {
  background: #980033;
}

.footerContent .innerListDiv p {
  margin-bottom: 0px;
}

.footerContent .innerListDiv p span {
  padding: 0px 10px;
}

.footerContent .rightColFooterDiv {
  padding-top: 80px;
}

.footerContent .rightHrDiv {
  width: 15%;
  border-width: 5px;
  margin-left: 20px;
}

.footerBottomContent {
  /* padding: 15px; */
  padding: 15px 80px;
}

.footerBottomContent p,
.footerBottomContent ul {
  margin-bottom: 0px;
}

.footerBottomContent ul li {
  list-style-type: none;
  padding: 0px 15px;
}

.rightColFooterDiv .innerListDiv {
  padding-right: 150px;
}

/*modal css*/
.modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  min-width: 85%;
  max-width: 85%;
  text-align: -webkit-center;
}

.modal-content {
  background: url("./images/PopupBackground.png");
  background-size: cover;
  background-position: center;
  padding: 20px;
  width: fit-content;
  margin-top: fit-content !important;
}

.modal-header .btn-close {
  font-size: 16px;
}

.loginModalDiv {
  text-align: center;
}

.loginModalDiv h1 {
  font-size: 44px;
  font-weight: 900;
  color: #212529;
}

.loginModalDiv h4 {
  font-size: 24px;
  color: #5e5e5e;
}

.loginModalDiv p {
  font-size: 18px;
  color: #212529;
}

.loginModalDiv hr {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 5px;
}

.loginModalDiv input {
  text-align: center;
  width: 50%;
  display: table;
  margin: 0 auto;
}

.loginModalDiv Button {
  padding: 6px 40px;
  border-radius: 0px;
}

.signinpara {
  margin-top: 40px;
  font-size: 15px;
  color: #212529;
}

/***************/
table {
  border: 0.5px solid #c4c4c4;
  margin: 15px 0px;
}

thead {
  background: #f9f9f9;
}

th,
td {
  padding: 8px !important;
}

.buttonStyle button {
  width: 48%;
}
#layout-wrapper .rotateScreen   {
  display: none
}
#layout-wrapper .container-fluid {
  display: block;
}
.highlightDiv {
  margin: 15px 0px;
  padding: 8px 12px;
  min-height: 200px;
  background: #f9f9f9;
  border: 0.5px solid #707070;
}

.DetailspageSliderDiv .slick-slide img {
  width: 80%;
  margin: 0 auto;
  height: 197px;
  border: 0px;
  outline: 0 !important;
}

.detailsDots {
  position: relative !important;
  display: flex !important;
  bottom: 0 !important;
  justify-content: space-between;
  width: 80% !important;
  margin: 0 auto !important;
}

.detailsDots li {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}

.detailsDots img {
  width: 100%;
  height: 100%;
}

.rightColMainHeader {
  padding-bottom: 10px;
}

/*newrequestdiv*/
.newRequestSummaryDiv {
  border: 0.5px solid #02c8f4;
  background: #f1fcff;
  padding: 15px;
}

.custCreationBuyerDiv {
  background: url(images/buyer_bg1.png);
}

.custCreationSupplierDiv {
  background: url(images/supplier_bg2.png);
}

.custCreationLogisticsDiv {
  background: url(images/logistics_bg3.png);
}

.ProductCategoryDiv {
  background: url(images/productcategory.png);
}

.AddProductDiv {
  background: url(images/addproduct.png);
}

.custCreationDiv {
  background-size: cover;
  background-position: center;
  padding: 25px 15px;
}

.custCreationDiv img {
  margin-right: 15px;
}

.custCreationDiv p {
  font-size: 20px;
  color: #fff;
}

.custCreationDiv button {
  font-size: 15px;
  background: #980033;
  padding: 5px 25px;
  color: #fff;
  box-shadow: none;
  border: 0px;
  border-radius: 4px;
}

/*customerDetails page*/
.formDivCss {
  color: #5e5e5e;
  font-size: 16px;
  font-weight: 500;
}

.formDivCss label span {
  color: #980033;
}

.formDivCss Form {
  padding: 0px 15px;
}

.formDivCss form-group {
  margin-bottom: 25px;
}

/*buyerForm page*/
.buyerBtn {
  padding-left: 10px;
}

.buyerBtn .btn {
  min-width: 100%;
}

/*profile page*/
.accbtn {
  padding-top: 15px;
}

.profilechangeBtnDiv span {
  padding: 0px 25px;
}

.profileHeading {
  font-size: 24px;
  font-weight: 700;
}

/*mainheader */
.logoutDropdown,
.notificationDropdown {
  position: absolute;
  width: 200px;
  right: 0;
  top: 50px;
  background: #fff;
  padding: 5px 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.logoutDropdown ul a li {
  border-bottom: 0.5px solid #c4c4c4;
  padding: 10px 0px;
}

.logoutDropdown ul a {
  text-decoration: none;
}

.logoutDropdown ul li img {
  margin-right: 10px;
}

.logoutDropdown ul a:last-child li {
  border-bottom: 0px;
}

.arrow-up {
  position: absolute;
  position: absolute;
  top: -12px;
  right: 25px;
  width: 0px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #fff;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.75);
}

.notificationDropdown {
  padding: 10px 0px;
  left: -85px;
  right: auto;
  width: 400px;
}

.notificationDropdown .arrow-up {
  left: 24%;
  right: auto;
}

.notificationDropdown ul {
  padding: 0px 10px;
}

.notificationDropdown ul a li {
  border-bottom: 0.5px solid #c4c4c4;
  padding: 10px 0px;
}

.notificationDropdown p {
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  color: #212529 !important;
  margin-bottom: 5px !important;
}

.notificationDropdown p span {
  color: #5e5e5e !important;
}

.notificationDropdown p:last-child {
  font-size: 13px !important;
  margin-bottom: 0px !important;
}

.notificationDropdown ul a li .innerNotificationDiv {
  padding: 15px;
  background: #f9f9f9;
}

.notificationDropdown ul a {
  text-decoration: none;
}

.notificationDropdown ul li img {
  margin-right: 10px;
}

.notificationDropdown ul a:last-child li {
  border-bottom: 0px;
}

/*service requests*/
.tableHeaderSearchDiv,
.tableHeaderSelectDiv,
.tableHeaderSelectDiv label {
  margin-bottom: 0px !important;
}

.tableHeaderSearchDiv input {
  width: 230px;
  margin-left: 10px;
}

.tableHeaderDivCss .btn {
  margin-left: 25px;
}

.tableHeaderSearchDiv svg {
  position: absolute;
  top: 10px;
  right: 10px;
}

.tableHeaderSelectDiv1 {
  width: 50% !important;
}

.tableHeaderSelectDiv1 input {
  width: 98% !important;
}

.tableHeaderSelectDiv2 {
  width: 30% !important;
}

.tableBtns Button {
  min-width: auto;
  padding: 2px 10px;
}

.tableHeaderSelectDiv {
  width: 200px;
  margin-left: 10px;
}

.tableHeaderSelectDiv .form-select {
  width: 70%;
}

.selectDivTwo {
  width: 125px;
}

.custCreationDivSec {
  padding: 0px !important;
}

.userRquestsFilterDropdown {
  width: 150px;
}

.productMainDivStyle {
  color: #fff;
  font-size: 18px;
}

.productMainDivStyle span {
  cursor: pointer;
}

.rightBtnDiv {
  float: right;
  margin-top: -20px;
}

.productMainFilterDropdown1 {
  width: 303px;
  left: 0px;
  right: auto;
  padding: 0px 10px;
  height: 316px;
  overflow-y: scroll;
}

.productMainFilterDropdown {
  width: 303px;
  left: 0px;
  right: auto;
  padding: 0px 10px;
  height: 405px;
  overflow-y: scroll;
}

.productMainFilterDropdown1 .arrow-up {
  left: 50px;
  right: auto;
}

.productMainFilterDropdown1 .card {
  padding: 15px;
  display: flex;
  margin-bottom: 0px !important;
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.productMainFilterDropdown1 .card .card-header {
  background: transparent;
  border: 0px;
  padding: 5px 0px;
}

.productMainFilterDropdown1 .card .card-body {
  padding: 15px 0px;
}

.productMainFilterDropdown1 form {
  padding: 15px;
  position: sticky;
  bottom: 0;
  /* z-index: 100; */
  background-color: white;
}

.productMainFilterDropdown1 .btn {
  margin-left: 8px;
  width: 49%;
}

.productMainFilterDropdown1 .buttonDivStyle {
  padding: 8px;
}

.productMainFilterDropdown .arrow-up {
  left: 50px;
  right: auto;
}

.productMainFilterDropdown .card {
  padding: 15px;
  display: flex;
  margin-bottom: 0px !important;
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.productMainFilterDropdown .card .card-header {
  background: transparent;
  border: 0px;
  padding: 5px 0px;
}

.productMainFilterDropdown .card .card-body {
  padding: 15px 0px;
}

.productMainFilterDropdown form {
  padding: 15px;
  position: sticky;
  bottom: 0;
  /* z-index: 100; */
  background-color: white;
}

.productMainFilterDropdown .btn {
  margin-left: 8px;
  width: 49%;
}

.productMainFilterDropdown .buttonDivStyle {
  padding: 8px;
}

.productCatDiv {
  padding: 7px 15px;
  background: #f9f9f9;
  border: 0.5px solid #c4c4c45e;
  border-radius: 4px;
  margin-right: 15px;
  width: 98%;
  cursor: pointer;
}

.existingproductMainFilterDropdown {
  width: 98% !important;
  left: 0px;
  right: auto;
  padding: 0px 10px;
}

.existingproductMainFilterDropdown .arrow-up {
  right: 15px;
  left: auto;
}

.svgTransformDiv {
  transform: rotate(180deg);
}

.productMainCatPopupDropdown,
.productMainCatPopupDropdown p {
  color: #212529 !important;
}

.productMainCatPopupDropdown .lightBtn {
  color: #5e5e5e !important;
  background: #fff !important;
  border: 0.5px solid #5e5e5e !important;
}

.productMainCatPopupDropdown .darkBtn {
  color: #fff !important;
  background: #5e5e5e !important;
  min-width: auto;
}

.productMainCatPopupDropdown .buyerBtn .darkBtn {
  width: auto;
  margin-left: 0px;
}

.productMainCatPopupDropdown .btn:first-child {
  margin-left: 0px;
}

.skuPopup .arrow-up {
  right: 120px;
  left: auto;
}

.sosDiv {
  padding: 15px !important;
}

.sosModalQuery .modal-content {
  padding: 20px;
  background: #fff;
}

.markResolvedBtn {
  color: #6c757d !important;
  background-color: #fff !important;
  /* border-color:  */
}

.discountOrderButton {
  width: 100%;
  padding: 9px 9px;
}

.queryToolTip {
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  width: 50px;
  overflow: hidden;
}

.sosModalDownDiv {
  float: right;
  margin-top: 10px;
}

.mainParentSectionDiv {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 170px;
  overflow-x: hidden;
  position: relative;
}

.mainParentSectionDiv1 {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 50px;
  overflow-x: hidden;
  position: relative;
}

.dataTableStyles .dataTables_info,
.dataTableStyles .dataTables_paginate {
  display: flex;
  color: #5e5e5e;
}

.dataTableStyles .dataTables_paginate {
  justify-content: flex-end;
}

.dataTableStyles .page-item.active .page-link {
  background: none;
  color: #212529;
}
.modal-title {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
}

.dataTableStyles .page-item.active .page-link,
.dataTableStyles .page-item .page-link,
.dataTableStyles .page-item {
  padding: 0px 5px;
  border: 0px;
  color: #c4c4c4;
}

.dataTableStyles .page-item.active .page-link span,
.dataTableStyles .page-item.disabled {
  display: none;
}

.dataTableStyles .page-item.active .page-link span:before {
  content: ">";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.dateClassDiv {
  margin-bottom: 0px !important;
}

.dateClassDiv input {
  width: 170px;
}

.inventoryFilterDiv .btn {
  margin-left: 15px;
}

.productPopupStyleImg {
  width: 100px;
  height: 100px;
}

.productPopupStyleImg img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 50%;
}

.custPaginationDiv ul li {
  padding: 0px 5px;
}

.custPaginationDiv ul li button {
  min-width: auto;
  color: #c3c3c3;
}

.custPaginationDiv .MuiPaginationItem-page:hover {
  background-color: transparent;
}

.custPaginationDiv .MuiPaginationItem-textPrimary.Mui-selected:hover,
.custPaginationDiv .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible,
.custPaginationDiv .MuiPaginationItem-textPrimary.Mui-selected {
  background-color: transparent;
  color: #212529;
  font-weight: 500;
}

.custPaginationDiv ul li:last-child button,
.custPaginationDiv ul li:first-child button {
  color: #212529;
}

.dateRangePickerDiv .e-input-group {
  background: #f9f9f9;
  padding: 3px 15px;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px;
}

.dateRangePickerDiv .e-input-group input::placeholder {
  color: #c4c4c4;
}

.dateRangePickerDiv
  .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after {
  background: transparent;
}

.productImageDivStyle {
  height: 150px;
  border: 1px solid #c4c4c4;
  padding: 20px;
  border-radius: 4px;
}

.productImageDivStyle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custCancelledChequeImageDiv {
  width: 100%;
  /* height: 150px; */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custCancelledChequeImageDiv img {
  width: auto;
  max-width: 100%;
  height: 100%;
}

.verifiedCustomer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custTableListingDivStyle {
  border: 0.5px solid #c4c4c45e;
  background: #fcfcfc;
  box-shadow: 0px 0px 2px -1px rgb(0 0 0 / 75%);
}

.skuImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.skuAlign {
  width: 150px;
}

.skuAlign p {
  width: 70%;
}

.colBorder {
  border: 1px solid;
}

.colOrder {
  border: 1px solid;
}

.colOrder h6,
.colOrder p {
  margin-bottom: 0px;
  padding: 5px 15px;
}

.colOrder p,
.innerOrderContentDiv {
  text-align: left;
  width: 30%;
}

.innerOrderContentDiv {
  padding: 5px;
}

.colLastOrder {
  background: #000;
  color: #fff;
  border: 0px;
}

.d-grid {
  display: grid;
}

/* :host ::ng-deep .modalDriver .modal-dialog{
  min-width: 50%;
  max-width: 50%;

} */
.modalDriverInner .modal-content {
  background: #ffffff;
  padding: 30px !important;
}

.modalDriverInner .modal-dialog {
  min-width: 50% !important;
  max-width: 50% !important;
}

.modalDriverInner .verifymodal {
  padding: 20px;
}

.popupFormParentDivCss {
  padding: 25px 0px;
}

.popupFormDivsCss {
  text-align: left;
  margin-bottom: 15px;
  display: block;
}

.popupFormDivsCss input {
  width: 100%;
}

.locationbtn {
  margin-top: 0px;
  margin-right: 60px;
  margin-bottom: 35px;
}

.orderColInnerDivStyle {
  /* border: 1px solid #ccc; */
  padding: 0px 10px;
  position: relative;
}

.orderColInnerDivStyle img {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.orderColInnerDivStyle input {
  width: -webkit-fill-available;
}

.orderLeftColStyle h4 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.orderLeftColStyle p {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.colcostLastOrder {
  padding: 10px;
  border-radius: 4px;
  border: 0.33px solid #707070;
  background: #000;
  color: #fff;
}
.orderHeader{
  font-size: 13px;
}
.colordercostDiv {
  border-radius: 4px;
  border: 0.33px solid #707070;
  background: #f9f9f9;
  padding: 5px 10px 0px 10px;
}

.colordercostDiv .commision {
  margin-bottom: 5px;
}

.amountDiv {
  padding-bottom: 25px;
}

.innerOrderContentDiv p {
  width: 100%;
  padding: 0px;
}

.colOrder h6 {
  border-right: 1px solid #333;
  min-height: 40px;
  width: 25%;
  display: flex;
  align-items: center;
}

.btn-center {
  text-align: center;
}

.orderDelete {
  padding: 5px;
  /* margin-bottom: 62px; */
  cursor: pointer;
}

.borderedDiv hr {
  color: #c4c4c4;
}

.borderedDiv ul {
  padding: 2px;
}

.borderedDiv ul li {
  border-right: 1px solid #C4C4C4;
  text-align: center;
  padding-right: 20px;
}

.noRightBorderPagination ul li {
  border-right: none;
}

.borderRightNone {
  border-right: 0px !important;
}

.ratingStartDiv {
  fill: #fff;
  stroke: #980033;
}

.ratingStarDiv1 svg:first-child {
  fill: #980033;
}

.ratingStarDiv2 svg:first-child,
.ratingStarDiv2 svg:nth-child(2) {
  fill: #980033;
}

.ratingStarDiv3 svg:first-child,
.ratingStarDiv3 svg:nth-child(2),
.ratingStarDiv3 svg:nth-child(3) {
  fill: #980033;
}

.ratingStarDiv4 svg:first-child,
.ratingStarDiv4 svg:nth-child(2),
.ratingStarDiv4 svg:nth-child(3),
.ratingStarDiv4 svg:nth-child(4) {
  fill: #980033;
}

.ratingStarDiv5 svg:first-child,
.ratingStarDiv5 svg:nth-child(2),
.ratingStarDiv5 svg:nth-child(3),
.ratingStarDiv5 svg:nth-child(4),
.ratingStarDiv5 svg:nth-child(5) {
  fill: #980033;
}

.addProductModalInner .modal-content {
  background: #ffffff !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

.selectWidth .basic-single {
  width: 80%;
}

.btn-right {
  margin-right: 15px;
}

.popupFormDivsCss span,
.novehicleValid {
  color: rgb(255, 0, 0);
}

.orderImg {
  width: 154px;
  height: 104px;
  border-radius: 5px;
}

.searchPhoneInput {
  /* width: 200px !important; */
  width: 160px !important;
}

.orderLabel {
  line-height: 18px;
}

.sortOrder,
.customerDowncontent {
  width: 150px;
}

.sortOrder input,
.sortOrder .basic-single {
  width: 100%;
}

.debounceInput input {
  width: 130px;
}

.paddingPayment {
  padding: 0;
}

.orderPaddingImg {
  padding: 7px;
}

.customerDowncontent {
  line-height: 18px;
}

.imgWidthDelivery {
  width: 150px;
  /* height: 150px; */
  object-fit: contain;
}

.rowProductImage {
  margin-right: -46px;
  margin-left: -47px;
  padding-top: 10px !important;
}

.rowRohuDiv {
  position: relative;
  top: 5%;
}

.rowCategoryDiv {
  position: relative;
  top: 8%;
}

.rowRatingDiv {
  position: relative;
  top: 12%;
  margin-bottom: 10px;
}

.confirmOrderImg {
  width: 100%;
  height: 236px;
  object-fit: contain;
}

.innerDivPosition {
  position: relative;
  height: 395px;
  overflow: auto;
}

.colorInnerDiv {
  width: 11px;
  height: 10px;
  /* display: initial; */
  position: relative;
  left: 27%;
  top: -12%;
}

.paddingOrderDelivery {
  padding: 10px 10px;
}

.marginBottomDelivery {
  margin-bottom: 24px;
}

.dotedLineDiv {
  width: 1px;
  height: 85%;
  border-left: 3px dashed;
  position: absolute;
  top: 8%;
  left: 41px;
}

.innerDivContent {
  /* height: 260px; */
  padding-top: 34px !important;
}

.paddingInnerContent {
  padding-left: 7px;
}

.Delivered,
.cancelledDiv {
  position: relative;
  top: -1%;
  left: 10%;
}

.In.Review {
  position: relative;
  top: 19%;
  left: 10%;
}

.In.Transit {
  position: relative;
  top: 41%;
  left: 10%;
}

.Finalized {
  position: relative;
  top: 52%;
  left: 10%;
}

.Discounted {
  position: relative;
  top: 68%;
  left: 10%;
}

.orderPlacedContent {
  position: relative;
  padding: 22px;
  /* top: 61%;
  left: 10%; */
}

.orderPlaceMarginRight {
  margin-right: 20px;
}

.orderPlacedDiv {
  width: 20px;
  height: 20px;
  background-color: #980033;
  border-radius: 50%;
}

.productVideosImgDIv {
  padding-top: 15px;
  margin-left: -20px;
}

.fontRoboto154 {
  font-weight: 400;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
}

.fontRoboto157 {
  font-weight: 700;
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
}

/* .raiseSosDiv {
  position: relative;
  top: 182px;
  left: -5px;
} */

.buttonSos {
  padding: 10px 10px;
}

.colorSkunameDiv {
  color: #980033;
}

.inputplus:hover {
  cursor: pointer;
}

/* preProductApprove */

.breadcrumb-item-list {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
}
.editPriceModel .modal-header .btn-close {
  font-size: 13px !important;
}
.editPriceModel .modal-content .table {
  border: 0.5px solid #c4c4c45e;
}
.editPriceModel .modal-content {
  background-size: 100% 100% !important;
  background: #ffffff;
  width: 40%;
}
.historyModel .modal-header .btn-close {
  font-size: 13px !important;
}
.historyModel .modal-content .table {
  border: 0.5px solid #c4c4c45e;
}
.historyModel .modal-content .table td {
  padding: 12px 5px !important;
}
.historyModel .modal-content {
  background-size: 100% 100% !important;
  background: #ffffff;
  width: 80%;
  height: 70%;
}
.editProductQuantity .modal-content {
  background-size: 100% 100% !important;
  background: #ffffff;
  width: 80%;
  height: 90%;
}
.editProductQuantity .modal-header .btn-close {
  font-size: 13px !important;
}
.editProductQuantity .form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}
.orderAdd .form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

/* preProductApprove */

/* setting */
.vl {
  border-left: 0.5px solid #c4c4c4 !important;
  height: 4px !important;
}

/* Create order deliverslot notification */
.notification-bottom {
  position: fixed;
  left: 2.18%;
  right: 73.33%;
  top: 92.46%;
  bottom: 3%;
  width: 26%;
  padding: 10px;
  border-radius: 4px;
  border: 0.5px solid #707070;
  background: #212529;
  justify-content: center;
  z-index: 1;
  height: max-content;
}
.sameSlotText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 13px;
  line-height: 22px;
  color: #ffffff;
  word-wrap: break-word;
}

.notification-close {
  position: absolute;
  right: 18px;
  top: 8px;
  cursor: pointer;
}
.infoButtonSpacing {
  margin-left: 9px !important;
}
.sellerCountIcon {
  margin: auto;
}
/* multiple seller start */

.sellerBorder {
  width: 100%;
  border-bottom: 0.5px solid #c4c4c4;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sellerBorder div {
  margin-right: 10px;
}
.sellerBorder .sellerPriceDiv {
  width: 100%;
}
.scrollSelectedSeller {
  height: 300px;
  overflow-y: auto;
}

.multiSellerAll span {
  padding-left: 10px;
  color: #980033;
}
.multiSellerAll .form-check-input:checked,
.productItemsCheckbox .form-check-input:checked {
  background-color: #980033 !important;
  border-color: #980033;
}
.productDataCheckbox .form-check-input:checked {
  background-color: #980033 !important;
  border: none;
}
.selectionColor {
  color: #980033;
}
.accordionImg {
  /* padding-left: 10px; */
  margin-left: 10px;
  width: 68px;
  height: 53px;
  border-radius: 7px;
  object-fit: contain;
}
.verticalCenter {
  vertical-align: baseline;
}
.accordionWidth {
  width: 90%;
}

.accordionSpan {
  float: right;
  margin-left: 10px;
  text-align: left;
  text-transform: capitalize;
}
.vlc {
  border-left: 0.5px solid #c4c4c4 !important;
  height: 4px !important;
}
.inputRedplus {
  width: 20px;
  height: 20px;
  background-color: #980033;
  color: #fff;
  top: -30px;
  left: 95%;
  border-radius: 3px;
  margin-top: 10px;
}
.sellerSize,
.accordionSpanFont,
.accordionDivProduct,
.sellerProductCount,
.accordionDarkId,
.sizeInnerContent {
  font-family: "Roboto";
  font-style: normal;
}
.sellerSize {
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.selectionProduct .modal-content {
  background: rgba(152, 0, 51, 0.75);
  color: #ffffff;
}
.categoryEditBtn {
  margin-right: 24px;
}
.accordionSeller .accordion-button {
  text-transform: capitalize;
}
.suggestedProduct {
  text-align: left;
}
.accordionSpanFont {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #5e5e5e;
}
.accordionDivProduct,
.accordionDarkId {
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
}
.accordionDivProduct {
  color: #212529;
}
.sellerProductCount {
  color: #c4c4c4;
}
.sellerProductCount span,
.sizeInnerContent {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
.sellerProductCount span {
  color: #5e5e5e;
}
.accordionSpanDark {
  color: rgba(255, 255, 255, 0.75);
}
.accordionDarkId {
  color: #ffffff;
}
.sizeInnerContent {
  color: rgba(255, 255, 255, 0.85);
}
.suggestedProductInput {
  background: rgba(217, 217, 217, 0.28);
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 4px;
}
.suggestedProductInput::placeholder {
  color: #ffffff;
}
.addNewProduct {
  margin-left: 10px;
  margin-top: 9px;
  color: #000000;
}
.NoProductAvail{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.limraPlusIcon{
  cursor: pointer;
  color:#980033 !important
}
.limraIcons{
  border-radius: 50%;
  border: solid #0FA958;
  padding:1px 3px 2px 5px ;
}
.limraToggle{
  margin-top: 10px;
}
.limraRateDiv .form-control{
  width: 100px;
  height: 50%;
}
.limraOrderIcons{
  position: relative;
  left: 30.5%;
  top: 110%;
}
.limraOrderDetailIcons{
  position: relative;
  height: 100%;
}
.newLogo{
  margin-left: 10px;
  width:80%;
  height:100%;
}
.newLogos{
  width: 50%;
}
/*multiple seller end */

/*********************/
@media screen and (max-width: 1240px) {
  .tableHeaderSearchDiv input {
    width: auto;
  }

  .tableHeaderDivCss {
    flex-wrap: wrap;
  }

  .sidebarProfilenameDiv img {
    width: 100%;
  }
}

@media screen and (max-width: 1145px) {
  .table th,
  .table td {
    line-height: 18px;
    font-size: 12px;
  }

  .tableBtns Button {
    padding: 2px 4px;
    font-size: 12px;
  }

  .resbuyer {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 992px) {
  .resbuyer {
    flex: unset;
  }
}

@media screen and (max-width: 992px) {
  .rightColFooterDiv .innerListDiv {
    padding-right: 0px;
  }

  .footerContent ul li {
    padding: 5px 10px;
  }

  .mainHeaderDiv {
    justify-content: space-between;
  }

  .rightColMainHeader {
    width: 70%;
  }

  .leftInnerColDiv {
    width: 70%;
  }

  .rightInnerColDiv {
    width: 30%;
  }
}

@media screen and (max-width: 800px) {
  .footerContent ul li {
    padding: 5px 7px;
  }
}

@media screen and (max-width: 768px) {
  .detailspagerow {
    display: block !important;
  }

  .detailspagerow .detailspageCol {
    width: 100%;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .detailspageCol .buttonStyle {
    display: block !important;
  }

  .detailspageCol button {
    width: 100%;
    margin-bottom: 25px;
  }

  .detailspageCol button:last-child {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .productDetailsMainContent .buttonDivStyle {
    margin-bottom: 25px;
  }

  .sliderDiv .card,
  .mainHeaderDiv,
  .mainHeaderDiv .innerHeaderRow {
    display: block !important;
  }

  .sliderDiv .card img,
  .footerContent .leftColFooterDiv,
  .footerContent .rightColFooterDiv,
  .mainHeaderDiv .innerHeaderRow .leftInnerColDiv,
  .mainHeaderDiv .innerHeaderRow .rightInnerColDiv,
  .mainHeaderDiv .leftColMainHeader,
  .mainHeaderDiv .rightColMainHeader,
  .footerContent .rightHrDiv {
    width: 100%;
  }

  .innerHeaderRow {
    margin: 0 auto;
  }

  .footerContent .rightColFooterDiv {
    padding-top: 0;
    text-align: center;
    justify-content: center !important;
  }

  .mainHeaderDiv .leftColMainHeader .navbar-brand {
    display: table;
  }

  .mainHeaderDiv .rightColMainHeader input {
    text-align: center;
    margin-bottom: 25px;
  }

  .footerContent .rightHrDiv,
  .footerContent .leftColFooterDiv .footerHrDiv,
  .mainHeaderDiv .leftColMainHeader .navbar-brand {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .footerContent .leftColFooterDiv .footerHrDiv {
    margin-top: 15px;
  }

  .footerContent .rightColFooterDiv ul,
  .mainHeaderDiv {
    justify-content: center !important;
  }

  .rightColFooterDiv .innerListDiv {
    padding-right: 0px;
  }

  .footerBottomContent {
    padding: 10px 15px;
  }

  .footerContent .leftColFooterDiv {
    text-align: center;
  }

  .filterProductsDiv Form {
    width: 60%;
  }
}

@media screen and (max-width: 640px) {
  .homeLoginDivCss {
    display: block;
  }

  .homeLoginDivCss .homeLogLeft,
  .homeLoginDivCss .homeLogRight {
    width: 100%;
  }

  /* 
  .orderPlacedContent {
    position: relative;
    top: 40%;
    left: 10%;
  } */

  .homeLoginDivCss .homeLogLeft {
    padding: 40px;
  }

  .footerContent ul li {
    padding: 10px 10px;
  }

  .footerContent .rightColFooterDiv ul {
    display: block !important;
    margin-bottom: 25px;
  }

  .filterProductsDiv {
    display: block !important;
  }

  .filterProductsDiv p {
    margin-bottom: 15px;
  }

  .productsContent .leftColInnerDiv {
    margin-bottom: 25px;
  }

  .mobileProductImage {
    padding-top: 41px;
  }

  .colordercostDiv {
    height: auto;
    min-height: -webkit-fill-available;
  }

  .mobilePaddingDiv25 {
    padding: 0px 25px;
  }

  .mobilePaddingDiv20 {
    padding: 0px 12px;
  }

  .mobilePackaging {
    padding-top: 20px;
  }

  /* .dotedLineDiv {
    left: 13.5%;
  } */

  .mobilePadding-7 {
    padding-left: 7px;
  }

  .rowProductImage {
    padding: 0px 41px;
  }

  .innerDivContent {
    padding-left: 20px;
  }

  .productVideosImgDIv {
    margin-left: 0px;
  }

  .fontRoboto154 {
    font-size: 12px;
  }

  /* .raiseSosDiv {
    top: 652px;
    left: 8px;
    padding-bottom: 49px;
  } */

  .raiseButtonWidth {
    width: 45%;
  }
}

@media screen and (max-width: 480px) {
  .CarouseDivTwo .card-body {
    border-right: 0px;
  }

  .fontRoboto154 {
    font-size: 13px;
  }

  /* .dotedLineDiv {
    left: 11.5%;
  } */

  .arrivedDiv {
    top: 14%;
    left: 5%;
  }

  .intransitDivContent {
    top: 27%;
    left: 5%;
  }

  /* .orderPlacedContent {
    top: 45%;
    left: 5%;
  } */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-image {
  display: none;
  /* position: absolute; */
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
}

.remove-image:active {
  background: #e54e4e;
}

.createorderbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.orderbuyerdetail {
  background-color: #efefef;
}

.buyerdetailbox {
  background: #f9f9f9;
  border: 0.5px solid #c4c4c4;
  border-radius: 4px;
  width: 204px;
  height: 138px;
  position: relative;
}

.buyerdetailbox img {
  object-fit: contain;
}

.plusiconsvg {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
}

.inputplus {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #000;
  color: #fff;
  top: -30px;
  left: 95%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 3px;
}

.placeorder {
  display: flex;
  align-items: center;
  justify-content: right;
}

.resbuyer {
  display: flex;
}

.orderlable {
  display: block;
  text-align: left;
}

.margintop {
  margin-top: 33px;
}

.debounceInput .orderidfix {
  width: 222px;
}

.buttonfix {
  display: flex;
  align-items: center;
}

.offlinepaymentbtn {
  text-align: center;
}

.footermodal {
  display: block;
}

.buyerdetailmodalinput {
  width: 40%;
}

.orderbuyerdetailflex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.plusicon {
  font-size: 30px;
  margin-right: 5px;
}

/* repeatorderBuyerDetail */
.repeatProduct {
  background-color: #fff0ee;
}
.repeatDelivery {
  color: #ff1a00;
}

.minusicon {
  font-size: 30px;
  margin-left: 5px;
}

.buyerdetailicon {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #555;
  color: #fff;
  border-radius: 29px;
  text-align: center;
  padding: 2px;
  font-size: 20px;
}

.buyerdetailicon:hover {
  cursor: pointer;
  background-color: #980033;
}

.modalbody {
  padding: 25px !important;
}

.privacyPolicy h3 {
  margin-bottom: 10px;
}

.privacyPolicy ul {
  margin-left: 15px;
}

.innerpolicy {
  padding-top: 60px;
}

.textCapitalize {
  text-transform: capitalize;
}

/*   */
@media (min-width: 280px) and (max-width: 788px) {
  .flexforres {
    display: flex;
  }
}

@media (min-width: 280px) and (max-width: 541px) {
  .addcustomerresponsive {
    font-size: 13px;
  }
}

@media (min-width: 462px) and (max-width: 1600px) {
  .display-flex {
    display: flex;
  }

  .profile-section {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 280px) and (max-width: 2500px) {
  .sidebarHeaderSec ul li,
  .menulink {
    list-style-type: none;
    padding: 10px 25px;
    font-size: 18px;
    font-weight: 400;
    color: #c4c4c4;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 280px) and (max-width: 865px) {
  .sidebarHeaderSec ul li,
  .menulink {
    list-style-type: none;
    padding: 0px 0px;
    font-size: 18px;
    font-weight: 400;
    color: #c4c4c4;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 865px) and (max-width: 992px) {
  .sidebarHeaderSec ul li,
  .menulink {
    padding: 10px 0px !important;
  }
}

@media (min-width: 720px) and (max-width: 1355px) {
  .respadding {
    padding: 0;
  }
}

/* @media (min-width: 280px) and (max-width: 720px) {
  .sidebarProfilenameDiv img {
    display: none;
  }
} */
@media (min-width: 992px) and (max-width: 1262px) {
  .newuserres {
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .lg-margin {
    margin-top: 8px;
  }
}

@media  (max-width: 580px) {
  .margin {
    margin-top: 8px;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.DeleteModal .close {
  border: none;
  font-size: 28px;
}

.EditModal .close {
  border: none;
  font-size: 28px;
}

/* Mozilla firefox */
@-moz-document url-prefix() {
  .modal-content {
    position: relative;
    left: 38%;
  }

  .DeleteModal .close {
    border: none;
    font-size: 28px;
  }

  .EditModal .close {
    border: none;
    font-size: 28px;
  }

  .EditModal .modal-content {
    position: relative;
    left: 30%;
  }
}

.orderdetailsbtn {
  display: flex;
}

.orderdetailtable {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* @media (min-width: 768px) and (max-width: 991.98px) {
  .orderdetailsubmitbtns {
    display: flex;
    align-items: center;
    justify-content: start;
  }
} */

/* css */
.HerecaName {
  white-space: nowrap;
}

.buttonWidth {
  height: 100%;
}

.EditModal .modal-content {
  padding: 25px;
  text-align: left;
}

/*  mobile break point*/

@media (max-width: 1366px) {
  .buttonPosition {
    position: relative;
    left: 10% !important;
  }

  .inputplus {
    left: 90% !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .paddingGrid {
    position: relative;
    padding-left: 8%;
  }
}

@media (min-width: 1025) and (max-width: 1180px) {
  .paddingGrid {
    position: relative;
    padding-left: 7% !important;
  }
}

@media (min-width: 1000px) {
  .buttonPosition {
    position: relative;
    left: 3%;
  }
}

@media screen and (max-width: 768px) {
  .inputplus {
    left: 86% !important;
  }
}

.profile-section {
  display: flex !important;
}

@media (max-width: 575.98px) {
  .loginDivCss .loginLogRight {
    padding: 40px 20px;
    width: 80%;
  }

  .textcenter {
    text-align: center;
  }
}

.redbordercolor:focus {
  border-color: red;
}

.dateinput {
  width: 100% !important;
}

.paymentParent {
  display: flex;
  margin: 20px;
}

.paymentProofImages {
  object-fit: contain;
  margin: 10px;
  background-color: white;
}

.downloadButton {
  display: inline;
  cursor: pointer;
  float: right;
  position: relative;
  right: -179px;
  top: 10px;
  font-size: 20px;
  background-color: #ffffff5c;
  border-radius: 50px;
  padding: 5px 6px;
}

.imagedwld {
  position: absolute;
}

@media (max-width: 390px) {
  .Delivered,
  .cancelledDiv {
    position: relative;
    top: 4%;
    left: 10%;
  }

  .In.Review {
    position: relative;
    top: 28%;
    left: 10%;
  }

  /* .orderPlacedContent {
    top: 35%;
    left: 10%;
  } */
}

.otpinput {
  background-color: #f9f9f9;
  border-color: #c4c4c4;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.otpinput:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

@media (min-width: 507px) and (max-width: 767px) {
  /* .paddingtopdiv {
    margin-top: 37%;
  } */
  /* .raiseSosDiv {
    top: 652px;
  } */
}

/* @media (min-width: 419px) and (max-width: 507px) {
  .paddingtopdiv {
    margin-top: 50%;
  }
} */

/* @media (min-width: 386px) and (max-width: 410px) {
  .paddingtopdiv {
    margin-top: 55%;
  }
} */

/* @media (max-width: 382px) {
  .paddingtopdiv {
    margin-top: 84%;
  }
} */

/* @media (min-width: 387px) and (max-width: 419px) {
  .paddingtopdiv {
    margin-top: 71%;
  }
} */

@media (max-width: 335px) {
  .Delivered,
  .cancelledDiv {
    position: relative;
    top: -11%;
    left: 10%;
  }

  .In.Review {
    position: relative;
    top: 20%;
    left: 10%;
  }
}

.imagecenter {
  text-align: center;
  margin: 5px;
}

/* 
@media (max-width: 375px) {
  .centerdiv {
    display: flex;
    justify-content: right;
  }
} */

.videodiv {
  width: 100%;
  height: 300px;
}

.downloadbtn {
  display: inline;
  cursor: pointer;
  float: right;
  position: relative;
  right: 14%;
  top: 5px;
}

@media (min-width: 576px) and (max-width: 640px) {
  .downloadbtn {
    right: 0%;
    top: -143px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .downloadbtn {
    right: 27%;
    top: 3px;
  }
}

@media (max-width: 575px) {
  .downloadbtn {
    right: 38%;
    top: 5px;
  }
}

.raiseSosDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  margin-left: 11%;
}

@media (max-width: 767px) {
  .raiseSosDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 299px;
    margin: auto;
  }
}

@media (max-width: 445px) {
  .dashInnerHeadDiv h4 {
    font-size: 14px;
  }
}

/* @media (min-width: 342px) and (max-width: 1500px) {
  .divbtns {
    display: flex;
  }

} */

/* @media (max-width: 767px){} */
.productSearch {
  display: flex;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loginModalMainDiv {
  min-width: 85%;
}

.loginModalMainDiv .modal-content {
  /* background: url("./images/PopupBackground.png"); */
  background-size: cover;
  background-position: center;
  padding: 40px;
}

.loginModalDiv {
  text-align: center;
}

.loginModalDiv h1 {
  font-size: 44px;
  font-weight: 900;
  color: #212529;
}

.loginModalDiv h4 {
  font-size: 24px;
  color: #5e5e5e;
}

.loginModalDiv p {
  font-size: 18px;
  color: #212529;
}

.loginModalDiv hr {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 5px;
}

.loginModalDiv input {
  text-align: center;
  width: 50%;
  display: table;
  margin: 0 auto;
}

.loginModalDiv Button {
  padding: 6px 40px;
  border-radius: 0px;
}

.productModalDiv .slick-prev,
.productModalDiv .slick-next {
  padding: 0px;
}

.productModalDiv .slick-next {
  right: -30px;
}

.productModalDiv .slick-prev {
  left: -30px;
}

.productModalDiv .modal-content {
  border: 0px;
  border-radius: 0px;
  background-color: transparent;
  height: 390px;
  width: 390px;
}

.slick-slide img {
  display: unset !important;
}

.coloumn-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.commission {
  display: flex;
  justify-content: center;
}

@media (min-width: 497px) and (max-width: 575px) {
  .commission {
    display: flex;
    justify-content: revert;
  }
}

.paymentodalbody.modal-body {
  overflow-y: auto;
  height: 300px;
}

.size-count {
  text-align: center;
}

/* .moq{
  text-align: center;
} */
.sorting_desc {
  text-align: center;
}

/* .table th {
  text-align: center !important;
} */

.outofstock {
  background-color: #980033;
}

.outofstocktext {
  color: #fff;
  text-align: center;
  padding: 10px;
  margin: 0;
}

/* .table td,
.table th {
  text-align: center;
} */

.walletdiv {
  background-color: #f1fcff;
  padding: 20px;
}

.accbal {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  color: #212529;
  margin-left: 40px;
}

.balamt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 27px;
  color: #212529;
}
.innerbaldiv {
  display: flex;
  align-items: center;
}
.outerdivbaldiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accountinfodiv {
  padding: 15px 15px !important;
}
th.discountTable {
  text-align: left !important;
}
td.discountTable {
  text-align: left;
}
.offlinepaymentcreatedby {
  text-align: -webkit-center;
}
.tablewidth {
  width: 100px;
}
.tablewidthofsos {
  width: 120px;
}
.CustomerFilterRow2 {
  margin-top: 15px;
}
.CustomerFilterRow1,
.CustomerFilterRow2 {
  display: flex;
  flex-direction: row;
}
.LandScapeDisplayNone {
  display: contents;
}
.LandScapeDisplay {
  display: none;
}
/* Destop css */
.mobLogo {
  display: none;
}
.tableBtns {
  display: flex;
}
.toggleIcon{
display: none;
}
/* media Query customer*/
@media screen and (orientation: landscape)  and (max-height:500px)  and (max-width: 1080px) {
  .LandScapeDisplayNone {
    display: none;
  }
  .LandScapeDisplay {
    font-size: 12px;
    display: contents;
  }
  .LandScapeDisplaysku .skuImage {
    display: none !important;
  }
  .LandScapeDisplaysku p {
    width: 100% !important;
  }
  .LandScapeDisplaysku {
    width: 100px !important;
  }
  .LandScapeDisplayprice{
    width: 200px;
  }
  .tableHeaderSearchDiv, .tableHeaderDivCss .btn, .tableHeaderSelectDiv .form-select{
    height: 30px;
    font-size: 12px;
    justify-content: center;
    text-align: center;
  }
  .borderedDiv ul {
    font-size: 12px;
    height: 100%;
  }
  .borderedDiv ul li {
    padding: 5px;
    height: 100%;
  }
  .borderRightNone .lightBtn{
    height: 40px;
    font-size: 10px;
    padding-top: 5px;
  }
  .borderRightNone .lightBtn{
    height: 40px;
    font-size: 10px;
    padding-top: 5px;
    width: 50px;
 }
  .bagIcon{
    height: 35px;
  }
  .buttonWidth{
    margin: 5px;
    height: 48px;
    font-size: 13px;
    overflow: hidden;
  }
  .minusicon, .plusicon{
    background-color:#5E5E5E;
    color:#fff;
    padding:5px 10px;
    border:none;
    font-size: 18px;
  }
  .plusicon{
    padding:5px 12px;
  }
  .productDetails{
    font-size: 14px !important;
  }
  .limraRateDiv .form-control{
    width: 60px;
    height: 50%;
  }
  .editPriceModel .modal-content {
    width: 60%;
    height: fit-content;
  }
  .editPriceModel .modal-content .modal-body .row .col-12{
    width: 50% !important;
  }
  .productPriceInput{
    height: 30px;
  }
  .input-group .limraInputSize{
    width: 80px !important;
  }
  .input-group{
    width: 120px !important;
  }
  .font-12px{
    font-size: 12px !important;
  }
  .accordion-button{
    padding: 10px !important;
  }
  .basicDetailBtn .btn{
    font-size: 12px !important;
  }
  .breadcrumb-item-list{
    font-size: 20px !important;
  }
}
@media screen and (orientation: landscape) and (min-width: 412px) and (max-width: 1080px) {
  /* body{
    overflow-y: scroll !important;
  } */
  .mobBody{
    overflow-y: scroll !important;
  }

  .menuText {
    display: flex;
    justify-content: center;
  }
  .menuText h1 {
    line-height: 20px;
  }
  .HrLine {
    display: flex;
    justify-content: center;
  }
  .HrLine hr {
    width: 50%;
  }
  .menuLinks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: 50px; */
  }
  .menuLinks div{
    margin-left: 30px;
    margin-top: 5px;
  }
  .menuBarLan {
    margin-top: -10px;
    height: 280px;
    width: 100%;
  }
  .DisplayNoneLand {
    display: none;
  }
  .svgStyleDivLandNone {
    display: none;
  }
  .toggleIcon {
    display: block;
    position: absolute;
    /* right: -41px; */
    z-index: 999;
    top: 34px;
    cursor: pointer;
  }
  .landscapeHide {
    display: none !important;
  }
  .tableHeaderSelectDiv2 {
    width: 90% !important;
  }
  .CustomerFilterRow1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 10px;
  }
  .form-control {
    font-size: 16px;
  }

  .custCreationDiv button {
    font-size: 10px;
    padding: 5px 15px;
  }
  .rightSideNavbar .rightSideNavbarRightDiv .menuProfileStyleDiv {
    margin-right: 8px;
  }
  .rightSideNavbar .rightSideNavbarRightDiv p {
    font-size: 14px;
  }
  .custCreationDiv p {
    font-size: 14px;
    font-weight: 700;
  }
  .sidebarProfilenameDiv h5 {
    margin-top: -9px;
    font-size: 14px;
  }
  /* .sidebarHeaderSec ul li,
  .menulink {
    font-size: 12px;
  } */
  .paginationCss {
    margin-bottom: 100px;
  }
  .newRequestSummaryDiv {
    display: none !important;
  }
  #layout-wrapper {
    background: #ffffff;
  }
  .mainParentSectionDiv {
    padding: 0px 0px 0rem 8px !important;
    font-size: 14px !important;
  }
  .dashInnerHeadDiv h4 {
    font-size: 16px !important;
    white-space: nowrap !important;
  }
  .rightSideNavbarMob {
    border: none;
    position: static;
  }
  .mobInnerHeadDiv {
    margin-top: -47px !important;
    font-size: 12px !important;
  }
  .custCreationDiv {
    padding: 7px 15px !important;
  }
  .borderedDiv {
    border: none;
  }
  .mobLogo {
    display: block;
  }

  .modal-content {
    width: 100%;
    margin-top: fit-content !important;
  }
  .width-92 {
    width: 100% !important;
  }
  .width-8 {
    width: 0px !important;
  }
  .width-25 {
    width: 25% !important;
  }
  .headerSidebar .svgStyleDiv {
    right: -65px !important;
  }
  .displayBlock .sidebarHeaderSec {
    width: 120% !important;
  }
  .rightSideNavbarMob .mobLogo {
    margin-left: 46% !important;
    transform: translateX(50%);
  }

  .rightSideNavbarLeftDiv h4 {
    display: none;
  }
  .dateRangePickerDiv .e-input-group input::placeholder {
    color: #000;
  }
  .tableHeaderSearchDiv input{
    height: 30px;
  }
}

@media screen and (orientation: landscape) and (min-width: 300px) {
  .mobCustRow 
    .col-sm-12 {
      flex: 0 0 auto;
      width: 50%;
  }
  
}

/* portrait */
@media screen and (orientation: portrait) and (min-width: 300px) and (max-width: 780px) {

  .mobBody{
    overflow-y: scroll !important;
  }
  .menuText {
    display: flex;
    justify-content: center;
  }
  .menuText h1 {
    line-height: 20px;
  }
  .HrLine {
    display: flex;
    justify-content: center;
  }
  .HrLine hr {
    width: 50%;
    height: 3px;
  }
  .menuLinks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: 50px; */
  }
  /* dashboard filter */
  
  .menuLinks div{
    margin-left: 25px;
    margin-top: 18px;
  }
  .menuLinks span {
    margin-left: 1px !important;
  }
  .menuBarLan {
    height: 280px;
    width: 100%;
  }
  .DisplayNoneLand {
    display: none;
  }
  .svgStyleDivLandNone {
    display: none;
  }
  .toggleIcon {
    display: block;
    position: absolute;
    /* right: -41px; */
    z-index: 999;
    top: 34px;
    cursor: pointer;
  }
  .landscapeHide {
    display: none !important;
  }
  .tableHeaderSelectDiv2 {
    width: 90% !important;
  }
  .CustomerFilterRow1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100px;
  }
  .form-control {
    font-size: 16px;
  }

  .custCreationDiv button {
    font-size: 12px;
    padding: 5px 15px;
  }
  .rightSideNavbar .rightSideNavbarRightDiv .menuProfileStyleDiv {
    margin-right: 8px;
  }
  .rightSideNavbar .rightSideNavbarRightDiv p {
    font-size: 14px;
  }
  .custCreationDiv p {
    font-size: 14px;
    font-weight: 700;
  }
  .sidebarProfilenameDiv h5 {
    margin-top: -9px;
    font-size: 14px;
  }
  /* .sidebarHeaderSec ul li,
  .menulink {
    font-size: 12px;
  } */
  .paginationCss {
    margin-bottom: 100px;
  }
  .newRequestSummaryDiv {
    display: none !important;
  }
  #layout-wrapper {
    background: #ffffff;
  }
  .mainParentSectionDiv {
    padding: 0px 0px 0rem 8px !important;
    font-size: 12px !important;
  }
  .sidebarHeaderSec ul {
  padding: 0px 0px 0px 0px;
}
  .dashInnerHeadDiv h4 {
    font-size: 14px !important;
    white-space: nowrap !important;
  }
  .rightSideNavbarMob {
    border: none;
    position: static;
  }
  .mobInnerHeadDiv {
    margin-top: -47px !important;
    font-size: 12px !important;
  }
  .custCreationDiv {
    padding: 7px 15px !important;
  }
  .borderedDiv {
    border: none;
  }
  .mobLogo {
    display: block;
  }

  .width-92 {
    width: 100% !important;
  }
  .width-8 {
    width: 0px !important;
  }
  .width-25 {
    width: 25% !important;
  }
  .headerSidebar .svgStyleDiv {
    right: -65px !important;
  }
  .displayBlock .sidebarHeaderSec {
    width: 120% !important;
  }
  .rightSideNavbarMob .mobLogo {
    margin-left: 37% !important;
    transform: translateX(50%);
  }

  .rightSideNavbarLeftDiv h4 {
    display: none;
  }
  .infoButtonSpacing ,.tableHeaderSearchDiv ,.mobShowFilter{
    margin-top: 10px;
  }
  .buyerdetailbox{
    width: 100%;
  }
  .modal-content {
    width: 100%;
}
}
@media screen and (max-width: 500px) {
  #layout-wrapper .rotateScreen{
    display: block;
  }
  #layout-wrapper .container-fluid  {
    display: none;
  }
  #layout-wrapper .rotateContent{
    padding-top: 50%;
  }
  #layout-wrapper .rotateContent .switchRotate{
    color: #02C8F4;
    font-weight: 400;
    font-size: 23px;
  }
  #layout-wrapper .rotateContent .switchRotateDes{
    color: #AC3059;
    font-weight: 400;
    font-size: 20px;
  }
}
.rotate{
  animation: rotate 2s linear infinite; 
}
@keyframes rotate{
  0%,20%{ transform: rotate(0deg);}
  50%,100%{ transform: rotate(-270deg);}
}
